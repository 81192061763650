header {
  padding: 10px;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 75px;

    svg {
      max-height: 100%;
      width: 100%;
    }
  }
}

.headerStyle {
  background-color: #FFF;
  padding: 0;
};

.titleStyle {
  font-size: 1.5rem;
  margin-bottom: 1rem;
};

.navStyle {
  display: flex;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    li {
      padding: 0.25rem .5rem;
    }

  }
};

.navbar {
  padding: 0;
}

.navItemStyle {
  list-style: none;
  margin: 0 1rem;
};

.add-btn {
  width: 100px;
  white-space: nowrap;
}

.navbar-toggler-icon {
  width: 1em !important;
  height: 1em !important;
}

.total-count {
  color: var(--bs-nav-link-color);
  color: #000;
  font-size: 0.875rem;
}
