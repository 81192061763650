.thumb-container {
  position: relative;
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.thumbs {
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;

  &_wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    flex-wrap: wrap;
  }

}

.thumbnail {
  margin: 0 0 0.25rem 0;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  transition: box-shadow .03s ease-in;

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    box-sizing: border-box;
    cursor: pointer;
  }

  &:hover {
    opacity: 0.75;
  }
}

.thumb-info {
  background-color: #ebebeb;
}

@media screen and (max-width: 990px) {
  .thumb-info {
    display: none;
  }
  
}

.new-item {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid #fff;
  background-color: rgb(0, 184, 0);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.thumb-info .new-item {
  position: relative;
}

.multiple-items {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #fff;
  background-color: #d34f01;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.thumb-info .multiple-items {
  position: relative;
}

.banner-container {
	width: 130px;
    height: 130px;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
	&:after {
    content: "";
    display: block;
    background-color: #ab1a1a;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
	}
	&:before {
    content: "";
    display: block;
    background-color: #ab1a1a;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
	}
}

.banner {
  width: 120px;
  background-color: #ab1a1a;
  font-size: 12px;
  color: #fff;
  text-align: center;
  transform: rotate(-45deg) translate(-30%, -35%);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
  text-transform: uppercase;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}


.info {
  &__container {
    display: flex;
    background-color: #f1f1f1;
    padding: 10px;
  }

  &__image-wrapper {
    display: flex;
    max-height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 480px;
  }

  &__image {
    display: block;
    object-position: center;
    object-fit: contain;
    box-sizing: border-box;
    border: 1px solid #333;

    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }

  &__content {
    flex: 1 1 100%;
    border-radius: 10px;
    background-color: #FFF;
    min-width: 300px;
    max-width: 50%;
    margin-left: 10px;
    position: relative;
    
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: left;
    }

    li {
      font-size: 12px;
      padding: 3px 5px;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:nth-child(odd) {
        background-color: #cfcfcf;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .info__container {
    flex-direction: column;

    .info__content {
      margin-left: 0;
      max-width: 100%;
    }
  }
}
