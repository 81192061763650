.pagination-wrapper {
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  border-top: 1px solid #55585c;

 .pagination {
  margin: 0;
 }

}
