html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  display: flex;
}

#root {
  flex: 1 1 100%;
}

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .main {
    flex: 1 1 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .scrollable-content {
      flex: 1 1 auto;
      overflow-y: auto;
      // overflow-x: hidden;
      padding: 1rem;
      background-color: #cecece;
    }

    &.scroll {
      overflow-y: auto;
    }
  }
}
