.filter-section {
  padding: 0.5rem;
  background-color: #55585c;

  .accordion-item {
    background-color: transparent !important;
    border: none;

    .accordion-button {
      padding: 0.5rem;
      background-color: transparent !important;
      color: #fff;

      &:after {
        fill: #FFF;
        filter: invert(100%);
    }
    }
  }
}

.mb-8 {
  margin-bottom: 8px;
}